import SEO from '@components/Common/SEO/index';
import useResizer from '@components/extra/useResizer';
import ProjectCard from '@components/ProjectCard/index';
import ProjectCategories from '@components/ProjectCategories/index';
import ProjectContribute from '@components/ProjectContribute/index';
import { Link } from 'gatsby';
import React from 'react';
import db from '../../data/db.json';
import * as styles from './ProjectCategoryPage.module.scss';
import downloadableProjects from '../../../utils/downloadable-projects';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import FooterV2 from '@components/FooterV2/index';
import Navigation from '@components/Navigation/index';

export default function ProjectCategoryPage({ pageContext, ...props }) {
  const projectCardImages = props.data.projectCardImages.nodes;
  const projectDetailImages = props.data.projectDetailImages.nodes;
  const sale = props.data.strapiSale.SaleID;

  const isMobile = useResizer();
  let projects = db['projects'][pageContext.categoryId];

  let remainingCategoriesList = [];
  let remainingCategories = Object.keys(db['projects']).filter((ele, idx) => {
    let categorySanitized = ele
      .split('-')
      .join(' ')
      .split(' ')
      .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(' ')
      .split(' ')
      .join('-')
      .toLowerCase();
    if (ele !== pageContext.categoryId) {
      db['projects'][ele].image = getProjectIcon(categorySanitized);
      db['projects'][
        ele
      ].url = `/projects/category/${categorySanitized}-projects`;

      remainingCategoriesList.push({
        ...db['projects'][ele],
        category: categorySanitized,
      });
    }
  });
  function getProjectIcon(category) {
    switch (category) {
      case 'java':
      case 'app-development':
      case 'machine-learning':
      case 'react':
      case 'web-development':
      case 'bash':
      case 'javascript':
      case 'python':
        return `projects/CategoryCards/${category}.png`;
      default:
        return `projects/CategoryCards/generic.png`;
    }
  }

  projects.meList.map((ele) => {
    if (downloadableProjects.includes(ele.meId)) {
      ele.download = true;
    } else {
      ele.download = false;
    }
  });
  projects.meList.sort(function (a, b) {
    return b.download - a.download;
  });

  //images
  let currentProjectDetailImage =
    projectDetailImages.find((ele) => ele.name == pageContext.categoryId) ||
    projectDetailImages.find((ele) => ele.name == 'generic');

  const image = getImage(currentProjectDetailImage.childImageSharp);

  return (
    <>
      <SEO
        title={pageContext.metaTitle}
        description={pageContext.metaDescription}
      ></SEO>
      <Navigation />

      <div
        style={{
          background: '#F3F1EE',
          // padding: "40px 20px",
        }}
      >
        <div className="container  ">
          <section className="pt-40 pb-20">
            <div className="container  ">
              <div className="row h-full">
                <div className="col-12 col-lg-8 mb-4 flex  flex-col  justify-center p-0  md:mb-0 md:ml-0 md:text-left ">
                  <div className="mb-4 w-max rounded-md bg-crio-yellow-500 p-1 pl-3 pr-3 text-sm font-semibold">
                    Completely free • Community Driven{' '}
                  </div>
                  <h3 className="breadcrumb flex  text-sm font-normal md:justify-start ">
                    <Link to={'/projects'}> Crio Projects &gt; </Link>
                  </h3>

                  <h1 style={{ color: '#075353' }}>
                    {pageContext.category} Projects
                  </h1>
                  <p className="mb-4 md:mr-5">{pageContext.hero} </p>
                </div>

                <div className="   col-12 col-lg-4 mb-5 flex items-center justify-center md:mb-0 md:justify-end">
                  <GatsbyImage
                    loading="lazy"
                    image={image}
                    alt={pageContext.category}
                    style={{ width: '300px' }}
                  />

                  {/* <Image
                    filename={pageContext.image}
                    style={{ width: '300px' }}
                  ></Image> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className={`pt-10 pb-6 ${styles.categorySection}`}>
        <div className="container   pb-20">
          <div className="row">
            {projects.meList.map((project, idx) => (
              <div className="col-12 col-lg-6 mb-6" key={project.meId}>
                <ProjectCard project={project}></ProjectCard>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ProjectCategories
        categories={remainingCategoriesList}
        images={projectCardImages}
      ></ProjectCategories>

      <ProjectContribute></ProjectContribute>
      <FooterV2 />
      {/* <Footer></Footer> */}
    </>
  );
}

export const pageQuery = graphql`
  query {
    strapiSale {
      ...strapiSaleFragment
    }

    projectCardImages: allFile(
      filter: { relativePath: { regex: "images/projects/CategoryCards/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 450
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
    projectDetailImages: allFile(
      filter: { relativePath: { regex: "images/projects/detail/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 450
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;
